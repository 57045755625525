<template>
  <section class="pa-0">
    <v-row
      v-if="!hideFilters && !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
      align="center"
    >
      <v-col cols="12" sm="12" md="3">
        <v-btn 
          :disabled="!esAdministradorDeContrato || (haveRole('ROLE_UFI') && !esAdministradorDeContrato)"
          color="secondary white--text" 
          @click="agregarRecepcion">
          Registrar recepción
        </v-btn>
      </v-col>
    </v-row>
    <data-table-component
      v-if="seguimientoContrato"
      :headers="headersEtapa"
      :items="etapasList"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters.total_rows"
      @paginar="paginar"
      :show_loading="cambiarEstado.isLoading"
    >
      <template v-slot:[`item.etapa`] ="{ item }">
        <div class="d-flex justify-center">
          <span class="font-weight-bold"> {{ item.etapa }} </span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                style="font-size: 18px !important;"
                class="ml-2"
                :color="item.RecepcionFactura ? '#4ade80 !important' : '#FFB200 !important'"
                >
                {{ item.RecepcionFactura ? 'mdi-receipt-text-check-outline' : 'mdi-receipt-text-clock-outline' }}
              </v-icon>
            </template>
            <span>{{ item.RecepcionFactura ? 'Factura enviada' : 'Factura pendiente' }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.fecha_recepcion`]="{ item }">
        {{
          item.fecha_inicio
            ? moment(item.fecha_inicio).format("DD/MM/YYYY")
            : moment(item.fecha_recepcion).format("DD/MM/YYYY")
        }}
      </template>
      <template v-slot:[`item.hora_recepcion`]="{ item }">
        {{ item.hora_recepcion }}
      </template>

      <template v-slot:[`item.fecha_fin`]="{ item }">
        {{ moment(item.fecha_fin).format("DD/MM/YYYY hh:mm") }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label :color="(item.EstadoRecepcion?.color)">
          <span class="white--text text-bold">{{ item.EstadoRecepcion ? item.EstadoRecepcion?.nombre :  "Pendiente" }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.calificacion`]="{ item }">
        {{
          item.Resenium
            ? item.Resenium.nota_promedio
            : "No cuenta con calificación"
        }}
      </template>
      <template v-slot:[`item.monto_a_pagar`]="{ item }">
        {{
          item.cantidad_pagar
            ? `$ ${Number(item.cantidad_pagar).toFixed(2)}`
            : "No cuenta con un monto a pagar"
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="!esUfi">
        <div class="d-flex justify-center" style="gap: 6px">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="ruta(item)" v-bind="attrs" v-on="on">
                mdi-eye
              </v-icon>
            </template>
            <span>Ver proceso</span>
          </v-tooltip>
          <v-tooltip top v-if="item.id_estado_recepcion === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="cambiarEstadoEnRecepcion(item.id, 2)" v-bind="attrs" v-on="on">
                mdi-cash-multiple
              </v-icon>
            </template>
            <span>Enviar a pago</span>
          </v-tooltip>
          <v-tooltip v-if="item.id_estado_recepcion === 2" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="abrirModalFactura(item.id, 2)" v-bind="attrs" v-on="on">
                mdi-invoice-text-send
              </v-icon>
            </template>
            <span>Registrar factura</span>
          </v-tooltip>
        </div>
      </template>
    </data-table-component>
    <div v-else class="d-flex flex-row justify-center mt-8">
      <v-progress-circular indeterminate color="primary" size="50" />
    </div>

    <div class="d-flex justify-center align-center mt-2" v-if="esAdministradorDeContrato">
      <v-btn
        class="flex-grow-1 flex-shrink-1 mt-4"
        color="secondary"
        large
        @click.stop="$router.back()"
      >
        Regresar
      </v-btn>
    </div>


    <ModalRecepcionFactura
      :isOpen="modalFactura"
      @close="modalFactura = false"
      @on-save="guardarAdjuntoFactura"
      :isLoading="cambiarEstado.isLoading"
    />
    
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";
import moment from "moment";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import ModalRecepcionFactura from "./ModalRecepcionFactura.vue";
import { convertToFormData, removePropsFromObjectIfNil } from "@/utils/data";
export default {
  components: {
    DataTableComponent,
    ModalRecepcionFactura,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cambiarEstado: createLoadable([]),
      headers: this.haveRole("ROLE_UFI", "ROLE_UFI_TECNICO")
        ? [
            { text: "Tipo de recepción", align: "start", value: "etapa" },
            { text: "Fecha de recepción", value: "fecha_recepcion", align: "center"},
            { text: "Hora de recepción", value: "hora_recepcion", align: "center" },
            { text: "Estado", value: "estado", align: "start" },
            { text: "Calificacion", value: "calificacion", align: "center" },
            { text: "Cantidades recibidas", value: "cantidad", align: "center" },
            { text: "Monto a pagar", value: "monto_a_pagar", align: "center" },
          ]
        : [
            { text: "Tipo de recepción", align: "start", value: "etapa" },
            { text: "Fecha de recepción", value: "fecha_recepcion", align: "center" },
            { text: "Hora de recepción", value: "hora_recepcion", align: "center" },
            { text: "Estado", value: "estado", align: "start" },
            { text: "Calificacion", value: "calificacion", align: "center" },
            { text: "Acciones", align: "center", value: "actions", sortable: false },
          ],
      menuInicio: false,
      botones: createLoadable([]),
      tipoRecepcion: false,
      fecha_inicio: null,
      menuFin: false,
      fecha_fin: null,
      estados: [
        { value: true, label: "Entregado" },
        { value: false, label: "Pendiente" },
        { value: null, label: "Todos" },
      ],
      modalFactura: false,
      adjuntoFactura: null,
      idRecepcion: null,
      idEstado: null,
    };
  },
  computed: {
    ...mapState("procesoCompra", [
      "idOrdenCompra",
      "etapasList",
      "etapaProgreso",
      "filters",
    ]),
    ...mapState("seguimientoOrden", ["seguimientoContrato", "montos"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    headersEtapa() {
      if (this.hideFilters) {
        return this.headers.filter((target) => target.value !== "actions");
      } else return this.headers;
    },
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    formattedFechaInicio() {
      if (this.fecha_inicio) {
        return moment(this.fecha_inicio).format("DD/MM/YYYY");
      } else return "";
    },
    formattedFechaFin() {
      if (this.fecha_fin) {
        return moment(this.fecha_fin).format("DD/MM/YYYY");
      } else return "";
    },
    botonesTipoRecepcion() {
      return this.botones.data;
    },
    esUfi() {
      return this.haveRoles(["ROLE_UFI", "ROLE_UFI_TECNICO"]);
    },
    esAdministradorDeContrato(){
      return this.seguimientoContrato?.esAdministradorContrato
    }
  },
  methods: {
    ...mapActions("procesoCompra", ["getEtapasContrato", "getEtapasProgreso"]),
    ...mapActions("seguimientoOrden", ["getEntregaContrato"]),
    ...mapMutations("procesoCompra", [
      "setFiltroFechaInicio",
      "setFiltroFechaFin",
      "setFiltroEstado",
      "setPage",
      "setPerPage",
      "limpiarEtapas",
    ]),
    ...mapMutations("seguimientoOrden", [
      "getEntregaData",
      "setEntregaEditable",
    ]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getEtapasContrato();
    },
    enviarFactura(recepcion){
      return recepcion.RecepcionFactura?.EstadoFactura.id === 1
    },
    createEntrega() {
      this.$router.push({
        name: "entrega-etapa-compra",
        params: {
          idContrato: this.$route.params?.idContrato,
        },
        query: {
          id_proveedor: this.$route.query.id_proveedor,
        },
      });
    },
    abrirModal() {
      this.tipoRecepcion = true;
      this.obtenerBotones();
    },
    async obtenerBotones() {
      toggleLoadable(this.botones);
      const { data } =
        await this.services.SeguimientoContractual.obtenerTipoRecepcion();
      setLoadableResponse(this.botones, data);
    },
    agregarRecepcion() {
      let path = null;
      if (this.seguimientoContrato?.contrato?.id_tipo_recepcion === 1){
        path = `/proceso-compra/recepcion-bienes/${this.$route.params?.idContrato}/${this.$route.params?.idProveedor}`;
      } else {
        path = `/proceso-compra/recepcion-obras-servicios/${this.$route.params?.idContrato}/${this.$route.params?.idProveedor}`;
      }

      this.$router.push({ path, query: { tipo_recepcion: this.seguimientoContrato?.contrato?.id_tipo_recepcion },
      });
    },
    ruta({ tipo_recepcion, id }) {
      let path = null;
      if (this.seguimientoContrato?.contrato?.id_tipo_recepcion === 1)
        path = `/proceso-compra/recepcion-bienes/${
          this.$route.params?.idContrato
        }/${this.$route.params?.idProveedor}${id ? `/${id}` : ``}`;
      else
        path = `/proceso-compra/recepcion-obras-servicios/${
          this.$route.params?.idContrato
        }/${this.$route.params?.idProveedor}${id ? `/${id}` : ``}`;
      this.$router.push({
        path,
        query: {
          tipo_recepcion,
        },
      });
    },
    cerrarModal() {
      this.tipoRecepcion = false;
    },
    abrirModalFactura(idRecepcion, idEstado){
      this.idRecepcion = idRecepcion;
      this.idEstado = idEstado;
      this.modalFactura = true;
    },
    async guardarAdjuntoFactura(adjunto) {
      this.adjuntoFactura = adjunto;

      await this.cambiarEstadoEnRecepcion(this.idRecepcion, this.idEstado, this.adjuntoFactura)
      this.modalFactura = false;
    },
    async cambiarEstadoEnRecepcion(idRecepcion, idEstado) {
      toggleLoadable(this.cambiarEstado);
      const payload = {
        id_estado: idEstado,
        adjunto: this.adjuntoFactura,
      }
      const keysParametros = Object.keys(payload);
      const formData = convertToFormData(removePropsFromObjectIfNil(payload, keysParametros));
      const { data } = await this.services.SeguimientoContractual.cambioEstadoRecepcion(idRecepcion, formData);
      setLoadableResponse(this.cambiarEstado, data, {
        showAlertOnSuccess: true,
        skipOnSuccess: true,
      });
      this.getEtapasContrato();
    },
  },
  beforeDestroy(){
    this.limpiarEtapas();
  },
};
</script>

<style></style>
